<template>
  <div class="wrapper register-wrapper">
    <div class="container flex-col items-center">
      <div
        class="flex flex-col w-8/12 rounded-md mt-12 p-5 shadow-lg register-container"
        v-if="!success"
      >
        <h2 class="self-center mb-5 mt-5">Parooli uuendamine</h2>
        <div class="form-group mb-5 w-8/12 mx-auto items-center">
          <h4>Uus parool</h4>
          <input
            type="password"
            v-model="password"
            class="w-full bg-offwhite text-2xl shadow-lg focus:shadow-outline"
          />
        </div>
        <div class="form-group mb-8 w-8/12 mx-auto items-center">
          <h4>Kinnita uus parool</h4>
          <input
            type="password"
            v-model="confirmPassword"
            class="w-full bg-offwhite text-2xl shadow-lg focus:shadow-outline"
          />
        </div>
        <div class="form-group mx-auto w-6/12 mb-5">
          <button @click="resetPassword()" class="new-button-green">
            <span class="label">Salvesta</span>
          </button>
        </div>
      </div>
      <div class="flex flex-col" v-if="success">
        Parool vahetatud!
        <button @click="$router.push('/login')" class="new-button-green">
          <span class="label">Logi sisse</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import RequestHandler from "@/assets/mixins/RequestHandler";
export default {
  data() {
    return {
      password: "",
      confirmPassword: "",
      success: false
    };
  },
  mixins: [RequestHandler],
  methods: {
    resetPassword() {
      const data = {
        password: this.password,
        token: this.$route.params.token
      };
      this.apiRequest(
        "password_reset/confirm/" + this.$route.params.token,
        "post",
        false,
        data
      ).then(res => {
        if (res.status === 200) {
          this.success = true;
        }
      });
    }
  }
};
</script>
